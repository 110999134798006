import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import { Toaster, toast } from 'react-hot-toast';
import { 
  Calendar,
  Clock,
  FileText,
  Plus,
  CheckCircle,
  XCircle,
  Trash2,
  Download,
  Upload,
  Filter,
  SortAsc,
  SortDesc,
  FileDown,
  X
} from 'lucide-react';
import BASE_API_URL from '../config';

// Confirmation Dialog Component
const ConfirmDialog = ({ isOpen, onClose, onConfirm, title, message }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="bg-white rounded-lg p-6 w-full max-w-md"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.9 }}
        >
          <h3 className="text-lg font-medium text-gray-900">{title}</h3>
          <p className="mt-2 text-sm text-gray-500">{message}</p>
          <div className="mt-4 flex justify-end space-x-3">
            <button
              onClick={onClose}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
            >
              Confirm
            </button>
          </div>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);

// Loading Spinner Component
const LoadingSpinner = () => (
  <div className="flex justify-center items-center py-4">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
  </div>
);

const LeaveManagementPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [workerId, setWorkerId] = useState('');
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [leaveType, setLeaveType] = useState('');
  const [file, setFile] = useState(null);
  const [allLeaves, setAllLeaves] = useState([]);
  const [workerNames, setWorkerNames] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [activeTab, setActiveTab] = useState('pending');
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [showConfirmDialog, setShowConfirmDialog] = useState({ show: false, type: null, id: null });
  const dropdownRef = useRef(null);

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSearchResults([]);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Throttle search API call
  useEffect(() => {
    const searchWorkers = async () => {
      if (searchTerm.length < 2) {
        setSearchResults([]);
        return;
      }
      try {
        const response = await axios.get(`${BASE_API_URL}/api/workers/search/${searchTerm}`);
        setSearchResults(response.data);
      } catch (error) {
        console.error('Error searching workers:', error);
        toast.error('Error searching workers');
      }
    };
    const timeoutId = setTimeout(searchWorkers, 300);
    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  // Fetch all leaves and worker names
  const fetchLeaves = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_API_URL}/api/leaves`);
      let leaves = response.data;

      // Apply sorting
      if (sortConfig.key) {
        leaves.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
          if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
          return 0;
        });
      }

      setAllLeaves(leaves);

      const workerIds = [...new Set(leaves.map((leave) => leave.workerId))];
      const workerNamesResponse = await axios.post(`${BASE_API_URL}/api/workers/names`, { workerIds });
      const namesMap = workerNamesResponse.data.reduce((acc, worker) => {
        acc[worker.id] = worker.Name;
        return acc;
      }, {});
      setWorkerNames(namesMap);
    } catch (error) {
      console.error('Error fetching leaves:', error);
      toast.error('Error fetching leaves');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLeaves();
  }, [sortConfig]);

  const pendingLeaves = allLeaves.filter(leave => leave.status === 'Pending');
  const approvedLeaves = allLeaves.filter(leave => leave.status === 'Approved');
  const rejectedLeaves = allLeaves.filter(leave => leave.status === 'Rejected');

  const selectWorker = (worker) => {
    setSelectedWorker(worker);
    setWorkerId(worker['Employee Number']);
    setSearchTerm(worker.Name);
    setSearchResults([]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    formData.append('leaveType', leaveType);
    formData.append('workerId', workerId);
    if (file) formData.append('file', file);

    try {
      setIsLoading(true);
      await axios.post(`${BASE_API_URL}/api/leaves`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      toast.success('Leave request submitted successfully!');
      setShowForm(false);
      fetchLeaves();
    } catch (error) {
      console.error('Error submitting leave:', error);
      toast.error('Failed to submit leave request');
    } finally {
      setIsLoading(false);
    }
  };

  const handleApproveReject = async (leaveId, status) => {
    try {
      setIsLoading(true);
      await axios.put(`${BASE_API_URL}/api/leaves/${leaveId}/approve`, { status });
      toast.success(`Leave ${status.toLowerCase()} successfully!`);
      fetchLeaves();
    } catch (error) {
      console.error('Error updating leave status:', error);
      toast.error('Failed to update leave status');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteLeave = async (leaveId) => {
    try {
      setIsLoading(true);
      await axios.delete(`${BASE_API_URL}/api/leaves/${leaveId}`);
      toast.success('Leave request deleted successfully!');
      setAllLeaves(allLeaves.filter(leave => leave.id !== leaveId));
    } catch (error) {
      console.error('Error deleting leave:', error);
      toast.error('Failed to delete leave request');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadOrUpload = async (leave) => {
    if (leave.fileUrl) {
      window.open(leave.fileUrl, '_blank');
    } else {
      document.getElementById(`fileInput-${leave.id}`).click();
    }
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const SortIcon = ({ column }) => {
    if (sortConfig.key !== column) return <SortAsc className="h-4 w-4 text-gray-400" />;
    return sortConfig.direction === 'asc' 
      ? <SortAsc className="h-4 w-4 text-indigo-600" />
      : <SortDesc className="h-4 w-4 text-indigo-600" />;
  };

  const getStatusBadgeColor = (status) => {
    switch (status) {
      case 'Pending': return 'bg-yellow-100 text-yellow-800';
      case 'Approved': return 'bg-green-100 text-green-800';
      case 'Rejected': return 'bg-red-100 text-red-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  const prepareCSVData = () => {
    const displayedLeaves = 
      activeTab === 'pending' ? pendingLeaves :
      activeTab === 'approved' ? approvedLeaves :
      activeTab === 'rejected' ? rejectedLeaves :
      allLeaves;

    return displayedLeaves.map(leave => ({
      'Employee Name': workerNames[leave.workerId] || 'N/A',
      'Employee ID': leave.workerId,
      'Leave Type': leave.leaveType,
      'Start Date': format(new Date(leave.startDate), 'yyyy-MM-dd'),
      'End Date': format(new Date(leave.endDate), 'yyyy-MM-dd'),
      'Days': leave.daysOfLeave,
      'Status': leave.status,
      'Submitted By': leave.submittedBy || 'N/A',
      'Approved By': leave.approvedBy || 'N/A'
    }));
  };

  const LeaveTable = ({ leaves, showActions = true }) => (
    <div className="overflow-x-auto">
      <div className="flex justify-end mb-4">
        <CSVLink 
          data={prepareCSVData()}
          filename={`leaves-${activeTab}-${format(new Date(), 'yyyy-MM-dd')}.csv`}
          className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          <FileDown className="h-4 w-4 mr-2" />
          Export CSV
        </CSVLink>
      </div>

      <table className="min-w-full bg-white">
        <thead>
          <tr className="bg-gray-50 border-b">
            <th onClick={() => handleSort('employeeName')} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase cursor-pointer hover:bg-gray-100">
              <div className="flex items-center space-x-1">
                <span>Employee Name</span>
                <SortIcon column="employeeName" />
              </div>
            </th>
            <th onClick={() => handleSort('workerId')} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase cursor-pointer hover:bg-gray-100">
              <div className="flex items-center space-x-1">
                <span>Employee ID</span>
                <SortIcon column="workerId" />
              </div>
            </th>
            <th onClick={() => handleSort('leaveType')} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase cursor-pointer hover:bg-gray-100">
              <div className="flex items-center space-x-1">
                <span>Leave Type</span>
                <SortIcon column="leaveType" />
              </div>
            </th>
            <th onClick={() => handleSort('startDate')} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase cursor-pointer hover:bg-gray-100">
              <div className="flex items-center space-x-1">
                <span>Duration</span>
                <SortIcon column="startDate" />
              </div>
            </th>
            <th onClick={() => handleSort('status')} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase cursor-pointer hover:bg-gray-100">
              <div className="flex items-center space-x-1">
                <span>Status</span>
                <SortIcon column="status" />
              </div>
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Submitted By</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Approved By</th>
            {showActions && <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {leaves.map((leave) => (
            <tr key={leave.id} className="hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap">{workerNames[leave.workerId] || 'N/A'}</td>
              <td className="px-6 py-4 whitespace-nowrap">{leave.workerId}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className="inline-flex rounded-full px-2 text-xs font-semibold leading-5 bg-blue-100 text-blue-800">
                  {leave.leaveType}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center space-x-2">
                  <Calendar className="h-4 w-4 text-gray-500" />
                  <span>{format(new Date(leave.startDate), 'MMM dd, yyyy')} - {format(new Date(leave.endDate), 'MMM dd, yyyy')}</span>
                  <span className="inline-flex rounded-full px-2 text-xs font-semibold bg-gray-100 text-gray-800">
                    {leave.daysOfLeave} days
                  </span>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${getStatusBadgeColor(leave.status)}`}>
                  {leave.status}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{leave.submittedBy || 'N/A'}</td>
              <td className="px-6 py-4 whitespace-nowrap">{leave.approvedBy || 'N/A'}</td>
              {showActions && (
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex space-x-2">
                    {leave.status === 'Pending' && (
                      <>
                        <button
                          onClick={() => setShowConfirmDialog({ show: true, type: 'approve', id: leave.id })}
                          className="inline-flex items-center px-3 py-1 border border-transparent text-sm rounded-md text-white bg-green-600 hover:bg-green-700"
                        >
                          <CheckCircle className="h-4 w-4 mr-1" />
                          Approve
                        </button>
                        <button
                          onClick={() => setShowConfirmDialog({ show: true, type: 'reject', id: leave.id })}
                          className="inline-flex items-center px-3 py-1 border border-transparent text-sm rounded-md text-white bg-red-600 hover:bg-red-700"
                        >
                          <XCircle className="h-4 w-4 mr-1" />
                          Reject
                        </button>
                      </>
                    )}
                    <button
                      onClick={() => setShowConfirmDialog({ show: true, type: 'delete', id: leave.id })}
                      className="inline-flex items-center px-3 py-1 border border-gray-300 text-sm rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => handleDownloadOrUpload(leave)}
                      className="inline-flex items-center px-3 py-1 border border-gray-300 text-sm rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      {leave.fileUrl ? <Download className="h-4 w-4" /> : <Upload className="h-4 w-4" />}
                    </button>
                    <input
                      type="file"
                      id={`fileInput-${leave.id}`}
                      className="hidden"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                        handleSubmit(e, leave.id);
                      }}
                    />
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {isLoading && <LoadingSpinner />}
      
      {!isLoading && leaves.length === 0 && (
        <div className="text-center py-8 text-gray-500">
          No leaves found
        </div>
      )}
    </div>
  );

  return (
    <div className="min-h-screen p-8 bg-gray-50 space-y-8">
      <Toaster position="top-right" />

      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold text-gray-900">Leave Management</h1>
        <button
          onClick={() => setShowForm(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <Plus className="h-4 w-4 mr-2" />
          New Leave Request
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Clock className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">Pending Approvals</dt>
                  <dd className="flex items-baseline">
                    <div className="text-2xl font-semibold text-gray-900">{pendingLeaves.length}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <FileText className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">Total Leave Requests</dt>
                  <dd className="flex items-baseline">
                    <div className="text-2xl font-semibold text-gray-900">{allLeaves.length}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8 px-6" aria-label="Tabs">
            {['pending', 'approved', 'rejected', 'all'].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`${
                  activeTab === tab
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm capitalize`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                <span className="ml-2 bg-gray-100 text-gray-900 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">
                  {tab === 'pending' ? pendingLeaves.length :
                   tab === 'approved' ? approvedLeaves.length :
                   tab === 'rejected' ? rejectedLeaves.length :
                   allLeaves.length}
                </span>
              </button>
            ))}
          </nav>
        </div>

        <div className="p-6">
          <LeaveTable
            leaves={
              activeTab === 'pending' ? pendingLeaves :
              activeTab === 'approved' ? approvedLeaves :
              activeTab === 'rejected' ? rejectedLeaves :
              allLeaves
            }
            showActions={activeTab !== 'approved' && activeTab !== 'rejected'}
          />
        </div>
      </div>

      {/* Confirmation Dialog */}
      <ConfirmDialog
        isOpen={showConfirmDialog.show}
        onClose={() => setShowConfirmDialog({ show: false, type: null, id: null })}
        onConfirm={() => {
          switch (showConfirmDialog.type) {
            case 'approve':
              handleApproveReject(showConfirmDialog.id, 'Approved');
              break;
            case 'reject':
              handleApproveReject(showConfirmDialog.id, 'Rejected');
              break;
            case 'delete':
              handleDeleteLeave(showConfirmDialog.id);
              break;
          }
          setShowConfirmDialog({ show: false, type: null, id: null });
        }}
        title={`Confirm ${showConfirmDialog.type}`}
        message={`Are you sure you want to ${showConfirmDialog.type} this leave request?`}
      />

      {/* Modal Form */}
      {showForm && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold text-gray-900">Submit Leave Request</h2>
              <button
                onClick={() => setShowForm(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <XCircle className="h-6 w-6" />
              </button>
            </div>
            
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="relative" ref={dropdownRef}>
                <label className="block text-sm font-medium text-gray-700">
                  Search Worker
                </label>
                <input
                  type="text"
                  placeholder="Type to search worker..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                {searchResults.length > 0 && (
                  <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-y-auto z-50">
                    {searchResults.map((worker) => (
                      <div
                        key={worker.id}
                        onClick={() => selectWorker(worker)}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <div className="font-medium text-gray-900">{worker.Name}</div>
                        <div className="text-sm text-gray-500">
                          ID: {worker['Employee Number']}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Start Date
                  </label>
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    End Date
                  </label>
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    required
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Leave Type
                </label>
                <select
                  value={leaveType}
                  onChange={(e) => setLeaveType(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  required
                >
                  <option value="">Select Leave Type</option>
                  <option value="Annual">Annual Leave</option>
                  <option value="Sick">Sick Leave</option>
                  <option value="Personal">Personal Leave</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Supporting Document
                </label>
                <div className="mt-1 flex items-center">
                  <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    className="block w-full text-sm text-gray-500
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-md file:border-0
                      file:text-sm file:font-semibold
                      file:bg-indigo-50 file:text-indigo-700
                      hover:file:bg-indigo-100"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Upload any supporting documents (optional)
                </p>
              </div>

              <div className="flex justify-end space-x-3 pt-4">
                <button
                  type="button"
                  onClick={() => setShowForm(false)}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Submit Request
                </button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default LeaveManagementPage;