import React, { useMemo, useRef, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { Card, CardHeader, CardTitle, CardContent } from "../components/ui/card";
import { ROSTER_API_URL } from '../config';
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { motion } from 'framer-motion';

const RosterPage = () => {
  const gridRef = useRef();
  const daysToShow = 90;
  const startDate = new Date('2025-01-01');

  const [rosterData, setRosterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startFilterDate, setStartFilterDate] = useState('');
  const [endFilterDate, setEndFilterDate] = useState('');
  const [showAddRosterPopup, setShowAddRosterPopup] = useState(false);

  const convertExcelDate = (excelDate) => {
    const baseDate = new Date(1900, 0, 1);
    baseDate.setDate(baseDate.getDate() + excelDate - 2);
    return baseDate;
  };

  const formatName = (name) => {
    const [lastName, firstName] = name.split(", ");
    return `${firstName || ''} ${lastName || ''}`;
  };

  const fetchRosterData = async () => {
    try {
      const response = await fetch(`${ROSTER_API_URL}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch roster data, status: ${response.status}`);
      }

      const data = await response.json();
      const processedData = data.map(employee => {
        const row = {
          ...employee,
          "Name": formatName(employee.Name),
          "Start Date": convertExcelDate(employee["Start Date"]).toLocaleDateString()
        };

        for (let i = 0; i < daysToShow; i++) {
          row[`day${i}`] = getRosterDay(employee.Roster, i, employee["Start Date"]);
        }
        return row;
      });

      setRosterData(processedData);
    } catch (error) {
      console.error("Error fetching roster data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getRosterDay = (rosterType, dayIndex, startDateExcel) => {
    const startDate = convertExcelDate(startDateExcel);
    const adjustedDayIndex = dayIndex % 14; // Modulo 14 for 10-4 patterns
  
    switch (rosterType) {
      case "1-7":
        return (dayIndex % 7) + 1;
  
      case "DIDO 10-4 A":
        if (adjustedDayIndex === 0) return "Drive In"; // First day is "Drive In"
        if (adjustedDayIndex === 9) return "Drive Out"; // 10th day is "Drive Out"
        return adjustedDayIndex < 10 ? (adjustedDayIndex % 10) + 1 : "R&R";
  
      case "FIFO 10-4 A":
        if (adjustedDayIndex === 0) return "Fly In"; // First day is "Fly In"
        if (adjustedDayIndex === 9) return "Fly Out"; // 10th day is "Fly Out"
        return adjustedDayIndex < 10 ? (adjustedDayIndex % 10) + 1 : "R&R";
  
      case "LOCAL 10-4 A":
        return adjustedDayIndex < 10 ? (adjustedDayIndex % 10) + 1 : "R&R"; // Just 1-10, then R&R
  
      case "DIDO 10-4 B":
        const shiftedDayIndexDIDO = (dayIndex + 7) % 14;
        if (shiftedDayIndexDIDO === 0) return "Drive In";
        if (shiftedDayIndexDIDO === 9) return "Drive Out";
        return shiftedDayIndexDIDO < 10 ? (shiftedDayIndexDIDO % 10) + 1 : "R&R";
  
      case "FIFO 10-4 B":
        const shiftedDayIndexFIFO = (dayIndex + 7) % 14;
        if (shiftedDayIndexFIFO === 0) return "Fly In";
        if (shiftedDayIndexFIFO === 9) return "Fly Out";
        return shiftedDayIndexFIFO < 10 ? (shiftedDayIndexFIFO % 10) + 1 : "R&R";
  
      case "LOCAL 10-4 B":
        return adjustedDayIndex < 10 ? (adjustedDayIndex % 10) + 1 : "R&R"; // Just 1-10, then R&R
  
      case "13-1 A":
        return adjustedDayIndex === 13 ? "R&R" : (adjustedDayIndex % 13) + 1;
  
      case "13-1 B":
        const shiftedDayIndex13B = (dayIndex + 7) % 14;
        return shiftedDayIndex13B === 13 ? "R&R" : (shiftedDayIndex13B % 13) + 1;
  
      case "6-1":
        return (dayIndex % 7) === 6 ? "R&R" : (dayIndex % 6) + 1;
  
      default:
        return ""; // Empty if the roster type is unrecognized
    }
  };
  

  const dateHeaders = useMemo(() => {
    const dates = [];
    for (let i = 0; i < daysToShow; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      const dayOfWeek = date.toLocaleDateString('en-AU', { weekday: 'short' });
      const dayOfMonth = date.getDate();
      
      dates.push({
        headerName: `${dayOfMonth}\n${dayOfWeek}`,
        field: `day${i}`,
        headerTooltip: date.toLocaleDateString('en-AU', { day: '2-digit', month: 'long', year: 'numeric' }),
        width: 120,
        cellRenderer: params => {
          const dayStatus = getRosterDay(params.data.Roster, i, params.data["Start Date"]);
          const isWeekend = [0, 6].includes(date.getDay());
          const cellStyle = {
            padding: '4px 8px',
            backgroundColor: dayStatus === "R&R" ? '#f0f0f0' : isWeekend ? '#ffebcc' : '#e6f7ff',
            color: dayStatus === "R&R" ? '#888888' : isWeekend ? '#cc7a00' : '#005f99',
          };
          return (
            <span style={cellStyle}>
              {dayStatus}
            </span>
          );
        },
      });
    }
    return dates;
  }, [daysToShow, startDate]);

  const columnDefs = [
    { headerName: 'Employee Number', field: 'Employee Number', pinned: 'left', width: 140 },
    { headerName: 'Employee', field: 'Name', pinned: 'left', width: 180 },
    { headerName: 'Position', field: 'Position', pinned: 'left', width: 160 },
    { headerName: 'Roster', field: 'Roster', pinned: 'left', width: 130 },
    { headerName: 'Section', field: 'Section', pinned: 'left', width: 150 },
    { headerName: 'Supervisor', field: 'Supervisor', pinned: 'left', width: 200 },
    ...dateHeaders,
  ];

  const onGridReady = params => {
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    fetchRosterData();
  }, []);

  // Add custom CSS to ensure scrollbars are always visible
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .always-show-scrollbars::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
      }
      .always-show-scrollbars::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .3);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }
      .always-show-scrollbars {
        overflow: scroll !important;
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  return (
    <div className="h-screen p-4 bg-gray-100 overflow-auto">
      <Button onClick={() => setShowAddRosterPopup(true)} className="mb-4 bg-green-600 hover:bg-green-700 text-white">
        Add New Roster Type
      </Button>

      {showAddRosterPopup && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
          >
            <h2 className="text-2xl font-semibold mb-4">Add New Roster Type</h2>
            <div className="grid grid-cols-7 gap-2 mb-4">
              {Array.from({ length: 14 }).map((_, i) => (
                <Input
                  key={i}
                  placeholder={`Day ${i + 1}`}
                  className="w-full p-2 text-center"
                />
              ))}
            </div>
            <Button onClick={() => setShowAddRosterPopup(false)} className="mt-4 bg-red-600 hover:bg-red-700 text-white">
              Close
            </Button>
          </motion.div>
        </motion.div>
      )}

      <Card className="h-full w-full rounded-lg shadow-lg overflow-hidden mt-4">
        <CardHeader className="bg-gradient-to-r from-[#2c2c54] to-[#1c1c3a] text-white">
          <CardTitle className="text-2xl">Blue-Collar Roster</CardTitle>
        </CardHeader>
        <CardContent className="p-4">
          <div className="flex space-x-4 mb-4">
            <Input
              type="date"
              placeholder="Start Date"
              value={startFilterDate}
              onChange={(e) => setStartFilterDate(e.target.value)}
              className="w-1/3"
            />
            <Input
              type="date"
              placeholder="End Date"
              value={endFilterDate}
              onChange={(e) => setEndFilterDate(e.target.value)}
              className="w-1/3"
            />
            <Button onClick={fetchRosterData} className="bg-blue-600 hover:bg-blue-700 text-white">
              Filter Roster
            </Button>
          </div>
          <div className="ag-theme-material w-full h-[calc(100vh-280px)] always-show-scrollbars">
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={rosterData}
              defaultColDef={{
                resizable: true,
                sortable: true,
                filter: true,
              }}
              onGridReady={onGridReady}
              className="w-full h-full"
              suppressDragLeaveHidesColumns={true}
              alwaysShowHorizontalScroll={true}
              alwaysShowVerticalScroll={true}
              headerHeight={48}
              suppressMovableColumns={false}
              suppressColumnMoveAnimation={true}
              enableCellTextSelection={true}
              ensureDomOrder={true}
              suppressColumnVirtualisation={true}
              rowBuffer={0}
              suppressScrollOnNewData={true}
              maintainColumnOrder={true}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default RosterPage;