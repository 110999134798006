import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Users, 
  Calendar, 
  FileSpreadsheet, 
  LogOut, 
  Menu, 
  X 
} from 'lucide-react';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  };

  const NavLink = ({ to, children, icon: Icon }) => {
    const isActive = location.pathname === to;
    const isHovered = hoveredItem === to;
    
    return (
      <Link
        to={to}
        onMouseEnter={() => setHoveredItem(to)}
        onMouseLeave={() => setHoveredItem(null)}
        className={`group relative flex items-center px-8 py-4 transition-all duration-300 rounded-lg
          ${isActive 
            ? 'bg-purple-500/10 text-white' 
            : 'text-gray-300 hover:bg-purple-500/5'
          }`}
      >
        <Icon 
          className={`w-5 h-5 transition-all duration-300 
          ${isActive ? 'text-purple-400' : 'text-gray-400 group-hover:text-purple-300'}`} 
        />
        <span className={`ml-4 text-sm font-medium tracking-wide transition-all duration-300
          ${isActive ? 'text-white' : 'text-gray-300 group-hover:text-white'}`}>
          {children}
        </span>
        
        {/* Subtle indicator line */}
        <div className={`absolute left-0 top-1/2 -translate-y-1/2 w-0.5 h-4 rounded-full
          transition-all duration-300 
          ${isActive ? 'bg-purple-400' : 'bg-transparent group-hover:bg-purple-400/20'}`} 
        />
      </Link>
    );
  };

  return (
    <div className="flex">
      {/* Desktop Sidebar */}
      <div className="hidden md:flex fixed top-0 left-0 h-full w-72 
        bg-gradient-to-b from-gray-900 to-gray-900/95 border-r border-white/5">
        <div className="flex flex-col w-full">
          {/* Logo Container */}
          <div className="flex items-center h-20 px-8 border-b border-purple-500/10">
            <img 
              src="/Asset 1.png" 
              alt="Logo" 
              className="h-6 w-auto" 
            />
          </div>

          {/* Navigation Section */}
          <div className="flex flex-col flex-grow px-4 py-8">
            <div className="space-y-1">
              <NavLink to="/dashboard" icon={LayoutDashboard}>Dashboard</NavLink>
              <NavLink to="/workers" icon={Users}>Workers</NavLink>
              <NavLink to="/roster" icon={Calendar}>Roster</NavLink>
              <NavLink to="/leave-form" icon={FileSpreadsheet}>Leave Form</NavLink>
            </div>
          </div>

          {/* Footer with Logout */}
          <div className="p-4 border-t border-purple-500/10">
            <button
              onClick={handleLogout}
              className="flex items-center w-full px-4 py-3 text-sm font-medium
                text-gray-300 hover:text-white transition-colors duration-300
                rounded-lg hover:bg-red-500/10 group"
            >
              <LogOut className="w-5 h-5 text-red-400 group-hover:text-red-300" />
              <span className="ml-4">Logout</span>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu Button */}
      <button
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        className="md:hidden fixed top-4 left-4 z-50 p-2 rounded-lg
          bg-purple-500/10 text-purple-300 hover:text-white 
          transition-colors duration-300"
      >
        {isMobileMenuOpen ? <X className="w-5 h-5" /> : <Menu className="w-5 h-5" />}
      </button>

      {/* Mobile Sidebar */}
      <div className={`md:hidden fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity duration-300 z-40
        ${isMobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
        <div className={`fixed top-0 left-0 h-full w-72 
          bg-gradient-to-b from-gray-900 to-gray-900/95 
          transform transition-transform duration-300
          ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}`}>
          <div className="flex flex-col h-full">
            {/* Mobile Logo */}
            <div className="flex items-center h-20 px-8 border-b border-purple-500/10">
              <img 
                src="/Asset 3.png" 
                alt="Logo" 
                className="h-8 w-auto" 
              />
            </div>

            {/* Mobile Navigation */}
            <div className="flex-grow px-4 py-8">
              <div className="space-y-1">
                <NavLink to="/dashboard" icon={LayoutDashboard}>Dashboard</NavLink>
                <NavLink to="/workers" icon={Users}>Workers</NavLink>
                <NavLink to="/roster" icon={Calendar}>Roster</NavLink>
                <NavLink to="/leave-form" icon={FileSpreadsheet}>Leave Form</NavLink>
              </div>
            </div>

            {/* Mobile Footer with Logout */}
            <div className="p-4 border-t border-purple-500/10">
              <button
                onClick={handleLogout}
                className="flex items-center w-full px-4 py-3 text-sm font-medium
                  text-gray-300 hover:text-white transition-colors duration-300
                  rounded-lg hover:bg-red-500/10 group"
              >
                <LogOut className="w-5 h-5 text-red-400 group-hover:text-red-300" />
                <span className="ml-4">Logout</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;