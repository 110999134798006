// context/RosterContext.js
import React, { createContext, useState, useEffect } from 'react';
import { ROSTER_API_URL } from '../config';

export const RosterContext = createContext();

export const RosterProvider = ({ children }) => {
  const [rosterData, setRosterData] = useState([]);
  const daysToShow = 90;

  // Fetch roster data from the API
  const fetchRosterData = async () => {
    try {
      const response = await fetch(`${ROSTER_API_URL}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) throw new Error('Failed to fetch roster data');
      const data = await response.json();
      const processedData = processRosterData(data);
      setRosterData(processedData);
    } catch (error) {
      console.error('Error fetching roster data:', error);
    }
  };

  // Process the fetched roster data
  const processRosterData = (data) => {
    return data.map((employee) => {
      const row = {
        ...employee,
        "Name": formatName(employee.Name),
        "Start Date": convertExcelDate(employee["Start Date"]).toLocaleDateString(),
      };
      for (let i = 0; i < daysToShow; i++) {
        row[`day${i}`] = getRosterDay(employee.Roster, i, employee["Start Date"]);
      }
      return row;
    });
  };

  const formatName = (name) => {
    const [lastName, firstName] = name.split(", ");
    return `${firstName || ''} ${lastName || ''}`;
  };

  const convertExcelDate = (excelDate) => {
    const baseDate = new Date(1900, 0, 1);
    baseDate.setDate(baseDate.getDate() + excelDate - 2);
    return baseDate;
  };

  const getRosterDay = (rosterType, dayIndex, startDateExcel) => {
    const startDate = convertExcelDate(startDateExcel);
    const dayOfWeek = new Date(startDate.getTime() + dayIndex * 86400000).getDay();
    switch (rosterType) {
      case "1-7":
        return (dayIndex % 7) + 1;
      case "DIDO 10-4 A":
      case "FIFO 10-4 A":
      case "LOCAL 10-4 A":
        return dayIndex % 14 < 10 ? ((dayIndex % 10) + 1) : "R&R";
      case "DIDO 10-4 B":
      case "FIFO 10-4 B":
      case "LOCAL 10-4 B":
        return (dayIndex + 7) % 14 < 10 ? (((dayIndex + 7) % 10) + 1) : "R&R";
      case "13-1 A":
        return dayOfWeek === 0 ? "R&R" : (dayIndex % 13) + 1;
      case "13-1 B":
        return (dayOfWeek === 0 && dayIndex % 14 === 13) ? "R&R" : (dayIndex % 13) + 1;
      case "6-1":
        return dayOfWeek === 0 ? "R&R" : dayOfWeek;
      default:
        return "";
    }
  };

  useEffect(() => {
    fetchRosterData();
  }, []);

  return (
    <RosterContext.Provider value={{ rosterData, fetchRosterData }}>
      {children}
    </RosterContext.Provider>
  );
};
