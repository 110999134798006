import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import BASE_API_URL from '../config';

const convertExcelDate = (serial) => {
  const excelEpoch = new Date(1900, 0, serial - 1);
  return excelEpoch.toISOString().split('T')[0]; // Format to YYYY-MM-DD
};

const rosters = [
  'FIFO 10-4 A',
  'FIFO 10-4 B',
  'DIDO 10-4 A',
  'DIDO 10-4 B',
  '13-1 A',
  '13-1 B',
  '6-1',
  '7',
  'LOCAL 10-4 A',
  'LOCAL 10-4 B',
  'TUE-THUR A',
  'TUE-THUR B',
  '5-2',
];

const WorkerManagement = () => {
  const [workers, setWorkers] = useState([]);
  const [filteredWorkers, setFilteredWorkers] = useState([]);
  const [worker, setWorker] = useState({
    employeeNumber: '',
    name: '',
    startDate: '',
    supervisor: '',
    position: '',
    roster: '',
    section: '',
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [editWorkerId, setEditWorkerId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchWorkers();
  }, []);

  const fetchWorkers = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/workers`);
      console.log('Fetched workers:', response.data); // Debugging
      setWorkers(response.data); // Ensure workers data is set properly
    } catch (error) {
      console.error('Error fetching workers:', error);
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWorker((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAddWorker = async () => {
    try {
      await axios.post(`${BASE_API_URL}/api/workers/add`, worker);
      alert('Worker added successfully');
      fetchWorkers();
      resetForm();
    } catch (error) {
      console.error('Error adding worker:', error);
    }
  };

  const handleEditWorker = (worker) => {
    setWorker(worker);
    setIsEditMode(true);
    setEditWorkerId(worker.id);
  };

  const handleUpdateWorker = async () => {
    try {
      await axios.put(`${BASE_API_URL}/api/workers/${editWorkerId}`, worker);
      alert('Worker updated successfully');
      fetchWorkers();
      resetForm();
    } catch (error) {
      console.error('Error updating worker:', error);
    }
  };

  const handleDeleteWorker = async (id) => {
    try {
      await axios.delete(`${BASE_API_URL}/api/workers/${id}`);
      alert('Worker deleted successfully');
      fetchWorkers();
    } catch (error) {
      console.error('Error deleting worker:', error);
    }
  };

  const resetForm = () => {
    setWorker({
      employeeNumber: '',
      name: '',
      startDate: '',
      supervisor: '',
      position: '',
      roster: '',
      section: '',
    });
    setIsEditMode(false);
    setEditWorkerId(null);
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
  
    if (!term) {
      // If search term is empty, show all workers
      setFilteredWorkers(workers);
      return;
    }
  
    const filtered = workers.filter((worker) => {
      const name = worker.Name?.toLowerCase() || ''; // Fallback to an empty string
      const position = worker.Position?.toLowerCase() || ''; // Fallback to an empty string
      const roster = worker.Roster?.toLowerCase() || ''; // Fallback to an empty string
  
      return (
        name.includes(term) ||
        position.includes(term) ||
        roster.includes(term)
      );
    });
  
    setFilteredWorkers(filtered);
  };
  
  useEffect(() => {
    // Ensure filteredWorkers is initialized with all workers initially
    setFilteredWorkers(workers);
  }, [workers]);
  
  const handleRowClick = (workerId) => {
    navigate(`/roster/${workerId}`); // Ensure this path matches your route
  };
  

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <Card className="max-w-8xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-gray-800">
            Blue-Collar Management
          </CardTitle>
        </CardHeader>
        <CardContent>
          {/* Search Input */}
          <div className="mb-4">
            <Input
              type="text"
              placeholder="Search workers..."
              value={searchTerm}
              onChange={handleSearch}
              className="w-full"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
            <Input
              type="text"
              name="employeeNumber"
              placeholder="Employee Number"
              value={worker.employeeNumber}
              onChange={handleInputChange}
              className="w-full"
            />
            <Input
              type="text"
              name="name"
              placeholder="Name"
              value={worker.name}
              onChange={handleInputChange}
              className="w-full"
            />
            <Input
              type="date"
              name="startDate"
              value={worker.startDate}
              onChange={handleInputChange}
              className="w-full"
            />
            <Input
              type="text"
              name="supervisor"
              placeholder="Supervisor"
              value={worker.supervisor}
              onChange={handleInputChange}
              className="w-full"
            />
            <Input
              type="text"
              name="position"
              placeholder="Position"
              value={worker.position}
              onChange={handleInputChange}
              className="w-full"
            />
            <select
              name="roster"
              value={worker.roster}
              onChange={handleInputChange}
              className="w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select Roster</option>
              {rosters.map((roster, index) => (
                <option key={index} value={roster}>
                  {roster}
                </option>
              ))}
            </select>
            <Input
              type="text"
              name="section"
              placeholder="Section"
              value={worker.section}
              onChange={handleInputChange}
              className="w-full"
            />
          </div>

          <div className="flex justify-end mb-8">
            <Button
              onClick={isEditMode ? handleUpdateWorker : handleAddWorker}
              className="bg-blue-600 hover:bg-blue-700 text-white"
            >
              {isEditMode ? 'Update Worker' : 'Add Worker'}
            </Button>
          </div>

          <div className="overflow-y-auto" style={{ maxHeight: '600px' }}>
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                    Employee Number
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                    Name
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                    Start Date
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                    Supervisor
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                    Position
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                    Roster
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                    Section
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
  {filteredWorkers.length > 0 ? (
    filteredWorkers.map((worker) => (
      <tr key={worker.id} className="border-t border-gray-200 hover:bg-gray-50">
        <td className="px-4 py-2 text-sm text-gray-700">
          {worker["Employee Number"] || 'N/A'}
        </td>
        <td
          className="px-4 py-2 text-sm text-blue-600 cursor-pointer"
          onClick={() => handleRowClick(worker.id)}
        >
          {worker.Name ? worker.Name.split(', ').reverse().join(' ') : 'N/A'}
        </td>
        <td className="px-4 py-2 text-sm text-gray-700">
          {worker["Start Date"] ? convertExcelDate(worker["Start Date"]) : 'N/A'}
        </td>
        <td className="px-4 py-2 text-sm text-gray-700">
          {worker.Supervisor ? worker.Supervisor.split('/').join(', ') : 'N/A'}
        </td>
        <td className="px-4 py-2 text-sm text-gray-700">
          {worker.Position || 'N/A'}
        </td>
        <td className="px-4 py-2 text-sm text-gray-700">
          {worker.Roster || 'N/A'}
        </td>
        <td className="px-4 py-2 text-sm text-gray-700">
          {worker.Section || 'N/A'}
        </td>
        <td className="px-4 py-2 text-sm space-x-2">
          <Button
            onClick={() => handleEditWorker(worker)}
            className="bg-amber-600 hover:bg-amber-700 text-white"
            size="sm"
          >
            Edit
          </Button>
          <Button
            onClick={() => handleDeleteWorker(worker.id)}
            className="bg-red-600 hover:bg-red-700 text-white"
            size="sm"
          >
            Delete
          </Button>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="8" className="px-4 py-2 text-center text-gray-500">
        No workers found.
      </td>
    </tr>
  )}
</tbody>
            </table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};


export default WorkerManagement;